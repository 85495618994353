import React, { useState } from "react"

import { withStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import ListProdutos from "./components/ListProdutos"
import ContentDiv from "../../components/ContentDiv"
import { produtos } from "./ProdutosUtils"

const ProdutosContainer = ({ classes }) => {
  const [produto, setProduto] = useState(null)
  const [value, setValue] = useState(0)

  const selectedProduct =
    produto && produtos[parseInt(produto[0])].produtos[parseInt(produto[1])]

  const listProdutos = !produto
    ? produtos[value].produtos
    : selectedProduct.moreProducts

  const title = !produto
    ? produtos.find((_, index) => index === value).linha
    : selectedProduct.titulo

  return (
    <>
      <div className={classes.buttonGroup}>
        {!produto &&
          produtos.map((produto, index) => {
            return (
              <Button
                key={index}
                color={index === value ? "primary" : "secondary"}
                onClick={() => setValue(index)}
                variant="contained"
              >
                {produto.marca}
              </Button>
            )
          })}
      </div>
      <ContentDiv>
        <div className={classes.row}>
          <div className={classes.sectionTitle}>
            <h2>{title}:</h2>
          </div>
          <div className={classes.voltar}>
            {produto && (
              <Button onClick={() => setProduto(undefined)} variant="contained">
                Voltar
              </Button>
            )}
          </div>
        </div>
        <ListProdutos
          produtos={listProdutos}
          setProduto={setProduto}
          id={value}
        />
      </ContentDiv>
    </>
  )
}

const styles = {
  buttonGroup: {
    paddingTop: 10,
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: 550,
    flexWrap: "wrap",
    "& > button": {
      margin: 10,
      minWidth: 231,
    },
  },
  buttonActive: {
    backgroundColor: "#071B60",
  },
  row: {
    display: "flex",
    alignItems: "center",
    paddingRight: 15,
    paddingLeft: 15,
  },
  sectionTitle: {
    position: "relative",
    width: "100%",
    "& > h2": {
      color: "#005251",
      fontWeight: 700,
      fontSize: "1.5em !important",
      textTransform: "uppercase",
    },
  },
  voltar: {
    display: "flex",
    justifyContent: "flex-end",
    maxHeight: 40,
  },
}

export default React.memo(withStyles(styles)(ProdutosContainer))
