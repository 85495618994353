import React from "react"

import { withStyles } from "@material-ui/core/styles"
import CardContainer from "./Card"

const ListProdutos = ({ classes, id, produtos, setProduto }) => {
  return (
    <div className={classes.row}>
      {produtos.map((data, index) => {
        const produtoId = `${id}${index}`
        return (
          <CardContainer
            key={index}
            setProduto={setProduto}
            id={produtoId}
            data={data}
          />
        )
      })}
    </div>
  )
}

const styles = {
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
}

export default React.memo(withStyles(styles)(ListProdutos))
