import analisador from "../../images/AnalisadorUmidade.png"
import balança from "../../images/Balança7Casas.png"
import balancaAnalitica from "../../images/balancaAnalitica.png"
import Ultramicrobalança from "../../images/Ultramicrobalança.png"
import BalancaPrecisao from "../../images/BalancaPrecisao.png"
import Microbalança from "../../images/Microbalança.png"
import densimetro from "../../images/Densimetro.png"
import eletrodo from "../../images/eletrodo.png"
import espectofotometro from "../../images/espectofotometro.png"
import labX from "../../images/LabX.png"
import medidorPh from "../../images/MedidorPH.png"
import MedidorPhPortatil from "../../images/MedidorPhPortatil.png"
import reator from "../../images/Reator.png"
import refratometro from "../../images/Refratometro.png"
import tga from "../../images/TGA.png"
import titulador from "../../images/TituladorT5.png"
import titulacaoExcellence from "../../images/titulacaoExcellence.png"
import tituladoresAutomaticos from "../../images/tituladoresAutomaticos.png"
import tituladoresPotenciometricos from "../../images/tituladoresPotenciometricos.png"
import amostradoresAutomaticos from "../../images/amostradoresAutomaticos.png"
import tituladoresKarl from "../../images/tituladoresKarl.png"

import VisorReflex from "../../images/VisorReflex.png"
import VisorTransparente from "../../images/VisorTransparente.png"
import VisorNívelMagnético from "../../images/VisorNívelMagnético.png"
import IluminadorNightStarMagnicator from "../../images/IluminadorNightStarMagnicator.png"
import VisorNívelBicolor from "../../images/VisorNívelBicolor.png"
import VálvulaAutomáticaShutoff from "../../images/VálvulaAutomáticaShutoff.png"
import VálvulaRetençãoSegurança from "../../images/VálvulaRetençãoSegurança.png"
import SistemaInteligenteMistura from "../../images/SistemaInteligenteMistura.png"

export const produtos = [
  {
    marca: "Mettler Toledo",
    linha: "Linha Lab",
    produtos: [
      {
        titulo: "Balanças",
        descricao:
          "Precisão, Analíticas, micro e ultra-micro Balanças com até 7 Casas Decimais",
        img: { src: balança },
        moreProducts: [
          {
            titulo: "Balanças de Precisão",
            descricao: `O excelente desempenho de pesagem e as 
            tecnologias inovadoras de manuseio de amostras 
            garantem que tarefas de pesagem desafiadoras 
            sejam eficientes, sem erros e em conformidade.`,
            img: { src: BalancaPrecisao },
          },
          {
            titulo: "Balanças Analíticas",
            descricao: `Nossas balanças analíticas apresentam uma ampla 
            faixa de resolução de 0,005 mg a 0,1 mg e 
            capacidades de 54 g a 520 g que atendem a todos 
            os seus fluxos de trabalho analíticos.`,
            img: { src: balancaAnalitica },
          },
          {
            titulo: "Microbalanças",
            descricao: `Resolução de 1 μg, repetibilidade de 1,5 μg até 50 g de capacidade máx.
            As balanças microanalíticas XPE oferecem a mais alta 
            capacidade aliada ao peso mínimo mais baixo possível. 
            `,
            img: { src: Microbalança },
          },
          {
            titulo: "Ultramicrobalanças",
            descricao: `
            A Micro Balança XS3DU mantém o seu processo
            de pesagem tão curto, econômico e eficaz quanto
            o necessário, o que lhe permite concentrar-se nos
            resultados.`,
            img: { src: Ultramicrobalança },
          },
        ],
      },
      {
        titulo:
          "Tituladores Potenciométricos, Kal Fisher Coulumétricos e Volumétricos",
        descricao: "",
        img: { src: titulador },
        moreProducts: [
          {
            titulo: "Titulação Excellence",
            descricao:
              "Modularidade combinada com titulação eficiente e processo seguro. Na medida exata para as suas necessidades.",
            img: { src: titulacaoExcellence },
          },
          {
            titulo: "Tituladores Potenciométricos Compactos",
            descricao: `Os Tituladores Potenciométricos Compactos da METTLER TOLEDO 
              foram especialmente desenvolvidos para aplicações de rotina, titulação 
              potenciométrica e operações simples.`,
            img: { src: tituladoresAutomaticos },
          },
          {
            titulo: "Tituladores Automáticos EasyPlus",
            descricao: `Os Tituladores Automatizados EasyPlus da METTLER TOLEDO 
            oferecem uma solução simples e de preço acessível para suas aplicações 
            automatizadas de titulação de rotina.`,
            img: { src: tituladoresPotenciometricos },
          },
          {
            titulo: "Amostradores Automáticos InMotion",
            descricao: `Atualmente a automação em um laboratório exige uma grande variedade de 
            amostras e fluxos de trabalho. A Automação não é mais analisar amostra por 
            amostra. Nossos amostradores automáticos são projetados para ajudar em todos os 
            sentidos fluxos de trabalho flexíveis e análise eficiente.`,
            img: { src: amostradoresAutomaticos },
          },
          {
            titulo: "Tituladores Karl Fischer",
            descricao: `Com os tituladores Karl Fischer da METTLER TOLEDO, os operadores 
            precisam apenas apertar um botão para executar uma determinação de água.`,
            img: { src: tituladoresKarl },
          },
        ],
      },
      {
        titulo:
          "Medidores de Ph, Ion Seletivo, Condutividade e Oxigênio Dissolvido",
        img: { src: medidorPh },
        descricao: `Esteja você buscando cumprir rígidos requisitos de conformidade ou realizar 
        medições de rotina dentro do orçamento, nós temos a solução de medidor de pH de bancada para você.`,
        moreProducts: [
          {
            titulo: "Medidor de pH de bancada",
            descricao: "Soluções Monocanal e Multicanal para o seu Laboratório",
            img: { src: medidorPh },
          },
          {
            titulo: "Medidor de pH portátil",
            descricao: `As soluções de medidor de pH portáteis da METTLER TOLEDO 
            são perfeitas para uso no laboratório, em linha e até mesmo em 
            ambientes externos.`,
            img: { src: MedidorPhPortatil },
          },
        ],
      },
      {
        titulo: "Sensores",
        descricao: "Mix Completo com eletrodo especifico para cada aplicação",
        img: { src: eletrodo },
      },
      {
        titulo: "Analisador de Umidade Profissional",
        descricao:
          "Desempenho excelente do analisador de umidade para a melhor qualidade do produto",
        img: { src: analisador },
      },
      {
        titulo: "Densimetros de Bancada e Portáteis",
        descricao: `Sinônimo de qualidade e inovação, a METTLER TOLEDO oferece 
        medidores de densidade digitais de bancada e portáteis de alta precisão`,
        img: { src: densimetro },
      },
      {
        titulo: "Refratômetro de Bancada e Portáteis",
        descricao: `A METTLER TOLEDO oferece refratômetros digitais de 
        bancada e portáteis de alta precisão para uma grande variedade de aplicações.`,
        img: { src: refratometro },
      },
      {
        titulo: "Espectofotômetro",
        descricao: `O design óptico superior dos nossos instrumentos UV/VIS permite sempre resultados confiáveis.`,
        img: { src: espectofotometro },
      },
      {
        titulo: "Reatores Automatizados",
        descricao:
          "Ponto de Gota ou amolecimento, Ponto de Fusão, Ebulição, Névoa e Deslizamento",
        img: { src: reator },
      },
      {
        titulo: "Caracterização de Materiais",
        descricao: "DSC, TGA, TMA e DMA",
        img: { src: tga },
      },
      {
        titulo: "Software LabX",
        descricao:
          "Integração completa de instrumentos em uma única plataforma atendendo plenamente CFR21 Parte 11",
        img: { src: labX },
      },
    ],
  },
  {
    marca: "Clark Reliance",
    linha: "Linha Caldeiraria",
    produtos: [
      {
        titulo: "Visor Reflex",
        descricao: "",
        img: { src: VisorReflex },
      },
      {
        titulo: "Visor Transparente",
        descricao: "",
        img: { src: VisorTransparente },
      },
      {
        titulo: "Visor de Nível Magnético",
        descricao: "",
        img: { src: VisorNívelMagnético },
      },
      {
        titulo: "Iluminador NightStar",
        descricao: "Magnicator II",
        img: {
          src: IluminadorNightStarMagnicator,
          style: { objectFit: "contain" },
        },
      },
      {
        titulo: "Visor de Nível Bicolor",
        descricao: "Simpliport",
        img: { src: VisorNívelBicolor },
      },
      {
        titulo: "Válvula Automática de Shutoff ou Válvula por Excesso de vazão",
        descricao: "",
        img: { src: VálvulaAutomáticaShutoff },
      },
      {
        titulo: "Válvula de retenção de segurança 360",
        descricao: "",
        img: { src: VálvulaRetençãoSegurança },
      },
      {
        titulo: "Sistema inteligente de Mistura",
        descricao: "Líquidos, gás ou vapor",
        img: { src: SistemaInteligenteMistura },
      },
    ],
  },
]
