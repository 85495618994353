import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Image from "../images/labProducts.jpg"
import Container from "../components/Container"
import Banner from "../components/Banner"
import Produtos from "../containers/ProdutosContainer"

const produtos = React.memo(({ classes, location }) => {
  return (
    <Container title="Produtos" page={2}>
      <div className={classes.root}>
        <Banner image={Image} text="Produtos" />
        <Produtos location={location} />
      </div>
    </Container>
  )
})

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}

export default withStyles(styles)(produtos)
