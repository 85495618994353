import React, { useState, useCallback } from "react"

import {
  TextField,
  Button,
  Grid,
  withStyles,
  Snackbar,
} from "@material-ui/core"
import SnackbarContent from "../../../components/SnackbarContent"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import axios from "axios"

function NumberFormatCustom(props) {
  const { inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask="(99) 99999-9999"
    />
  )
}

const DetailForm = ({ classes }) => {
  const { register, handleSubmit, errors } = useForm()

  const [open, setOpen] = useState(null)

  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "clickaway") {
        return
      }
      setOpen(null)
    },
    [setOpen]
  )

  const onSubmit = useCallback(
    data => {
      axios
        .post(`${window.location.origin}/sendCotacao`, { ...data })
        .then(({ data: { success } = {} }) => {
          success && setOpen("success")
        })
        .catch(e => {
          setOpen("error")
        })
    },
    [setOpen]
  )

  const hasError = Object.entries(errors).length > 0

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            placeholder="Digite o nome"
            name="nome"
            label="Nome"
            error={!!errors.nome}
            variant="outlined"
            fullWidth
            classes={{ root: classes.inputStyle }}
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="email"
            placeholder="Digite o email"
            name="email"
            label="Email"
            variant="outlined"
            error={!!errors.email}
            fullWidth
            classes={{ root: classes.inputStyle }}
            inputRef={register({ required: true, pattern: /^\S+@\S+$/i })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            placeholder="Digite o telefone"
            name="telefone"
            label="Telefone"
            variant="outlined"
            error={!!errors.telefone}
            fullWidth
            classes={{ root: classes.inputStyle }}
            inputRef={register({ required: true, maxLength: 12 })}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            placeholder="Digite o nome da empresa"
            name="empresa"
            label="Empresa"
            variant="outlined"
            error={!!errors.empresa}
            fullWidth
            classes={{ root: classes.inputStyle }}
            inputRef={register({ required: true })}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            name="mensagem"
            label="Digite uma mensagem"
            rows="4"
            variant="outlined"
            error={!!errors.mensagem}
            fullWidth
            classes={{ root: classes.inputStyle }}
            multiline
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            disabled={hasError}
            type="submit"
            color="primary"
            variant="contained"
          >
            Solicitar
          </Button>
          {!!open && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={!!open}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <SnackbarContent onClose={handleClose} variant={open} />
            </Snackbar>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

const styles = {
  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 0,
    flexBasis: "50%",
    height: "100%",
  },
  "@media (max-width: 992px)": {
    form: {
      flex: 1,
      maxWidth: "100%",
      flexBasis: "100%",
      marginTop: 30,
      order: 2,
    },
  },
}

export default React.memo(withStyles(styles)(DetailForm))
