import React, { useCallback, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Modal, Fade } from "@material-ui/core"
import { HighlightOff } from "@material-ui/icons"
import DetailForm from "./Form"

const useStyles = makeStyles(theme => ({
  modal: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "& > h2": {
      color: "#005251",
      marginBottom: 15,
      fontWeight: 600,
    },
  },
  closeButton: {
    cursor: "pointer",
    float: "right",
    margin: 5,
    width: 20,
    position: "absolute",
    right: 0,
    top: 0,
  },
  content: {
    width: "100%",
    marginTop: 15,
    border: "1px solid #e8e9eb",
    padding: "26px 20px 60px",
    textAlign: "center",
  },
  img: {
    width: 200,
    height: 200,
    border: "1px solid #e8e9eb",
  },
  singleService: {
    "-webkit-transition": "0.6s",
    transition: "0.6s",
    height: "100%",
    "& > h2": {
      color: "#005251",
      marginBottom: 15,
      fontWeight: 600,
    },
    "& > p": {
      color: "#7f7f7f",
    },
  },
  "@media (max-width: 1360px)": {
    content: {
      fontSize: "1.2em !important",
      padding: "26px 20px 80px",
    },
  },
  "@media only screen and (min-width: 1200px)": {
    content: {
      flex: "0 1 calc(33.33% - 1em)",
    },
  },
  "@media (max-width: 1200px)": {
    content: {
      flex: "0 1 calc(50% - 1em)",
    },
    modal: {
      width: "70%",
    },
  },
  "@media (max-width: 991px)": {
    content: {
      padding: "26px 20px 90px",
    },
    singleService: {
      marginTop: 25,
    },
  },
  "@media (max-width: 576px)": {
    content: {
      "-ms-flex": "initial",
      flex: "initial",
      maxWidth: "initial",
    },
    modal: {
      width: "90%",
    },
  },
}))

const CardContainer = ({
  data: { img, titulo, descricao, moreProducts },
  id,
  setProduto,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCick = useCallback(() => {
    setProduto(id)
  }, [setProduto, id])

  const isClickableProp = moreProducts && {
    onClick: handleCick,
    style: { cursor: "pointer" },
  }

  return (
    <div className={classes.content}>
      <div className={classes.singleService}>
        <img
          className={classes.img}
          style={img.style}
          alt={titulo}
          src={img.src}
          {...isClickableProp}
        />
        <h2 {...isClickableProp}>{titulo}</h2>
        <p>{descricao}</p>
      </div>

      <Button onClick={handleOpen} variant="outlined" color="primary">
        Solicitar cotação
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={classes.modal}>
            <h2>Solicitar cotação:</h2>
            <HighlightOff
              onClick={handleClose}
              className={classes.closeButton}
            />
            <p>{titulo}</p>
            <DetailForm />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default CardContainer
